import SubTypeSelect from '@/components/incidents/elements/SubTypeSelect/SubTypeSelect.vue';
import { mapActions } from 'vuex';

export default {
	name: 'SubTypeSelect',
	props: {
		items: {
			type: Array,
			required: false,
		},
		marginLeft: {
			type: [Number, String],
			required: false,
		},
		parent: {
			type: [Object],
			required: false,
		},
	},
	data() {
		return {
			selectedSubType: null,
			requireRule: [(v) => !!v || 'Campo requerido'],
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {
		items: function (newValue) {
			if (Array.isArray(newValue)) {
				const founded = newValue.some(
					(item) => item?.id == this.selectedSubType?.id
				);
				if (!founded) {
					this.selectedSubType = null;
				}
			}
		},
	},
	methods: {
		...mapActions('subtype', ['changeSelectedSubtype', 'switchFlagObserveAsset','switchFlagRequiredAsset']),

		verifyParent() {
			if (this.parent) {
				return this.selectedSubType?.parent_subtype_id === this.parent?.id;
			}
			return true;
		},

		hasChildren(subtype) {
			const children = subtype?.children;
			return this.verifyParent() && Array.isArray(children) && children.length > 0;
		},

		listenChange(value) {
			this.changeSelectedSubtype(value?.id || null);
		
			if (
				Array.isArray(value?.children) &&
				value?.children?.length === 0 &&
				value?.flag_observe_asset
			) {
				this.switchFlagObserveAsset(true);
			} else {
				this.switchFlagObserveAsset(false);
			}

			if (
				Array.isArray(value?.children) &&
				value?.children?.length === 0 &&
				value?.flag_required_asset
			) {
				this.switchFlagRequiredAsset(true);
			} else {
				this.switchFlagRequiredAsset(false)
			}
		},
	},
	beforeDestroy() {
		this.selectedSubType = null;
	},
	components: {
		SubTypeSelect,
	},
};
